/* import "./jquery-global.js";
import $ from "jquery"; */
import Dropdown from 'bootstrap/js/dist/dropdown'
import Collapse from 'bootstrap/js/dist/collapse'
import Modal from 'bootstrap/js/dist/modal'
import Tabs from 'bootstrap/js/dist/tab'

import "./custom.js";

import "./modules/module-heroswiper/module-heroswiper.js";
import "./modules/module-bigteaserswiper/module-bigteaserswiper.js";

